import React from "react";
import "./style.css";


export const Error = () => {
  return (
    <div className="text-center error">
      <p>4O4</p>
    </div>
  );
};
