import React from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Typewriter from "typewriter-effect";
import { introdata, meta } from "../../content_option";
import { Link } from "react-router-dom";

export const Contact = () => {
  return (
    <HelmetProvider>
      <section id="home" className="home">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{meta.title}</title>
          <meta name="description" content={meta.description} />
        </Helmet>
        <div className="intro_sec d-flex flex-column flex-lg-row align-items-center">
          <div
            className="h_bg-image order-1 order-lg-2 h-100"
            style={{ backgroundImage: `url(${introdata.your_img_url})` }}
          ></div>
          <div className="text order-2 order-lg-1 d-flex align-items-center justify-content-center h-100">
            <a
              href="https://github.com/OxFOIQ"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="w-50"
                src="https://lh3.googleusercontent.com/proxy/RoPcmEVMBSgWrFW1o1e2zAMssiAvZHDxzHM_U2JaCEBn7r8nLUxiauFGk5Oy_Y8CJqOr0OZSJ9V3tJ_Mj4ITBJNOuwFT=s0-d"
                alt=""
              />
            </a>
            <a
              href="https://linkedin.com/in/medamyyne"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="w-75"
                src="https://brintonvision.com/wp-content/uploads/2016/05/linkedin-black.png"
                alt="linkedin"
              />
            </a>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};
