import React from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { meta } from "../../content_option";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import machanger from "../../assets/images/machanger.jpg";
import QuestorNet from "../../assets/images/QuestorNet.jpg";
import EpiScan from "../../assets/images/episcan.jpg";
import Alphascan from "../../assets/images/alphascan.jpg";
import swindle from "../../assets/images/swindle.jpg";
import spyfire from "../../assets/images/spyfire.png";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export const Projects = () => {
  const cardStyle = {
    maxWidth: "23rem",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    border: "none",
    marginRight: "25px",
    marginLeft: "25px",
  };

  return (
    <>
      <section id="home" className="home d-flex justify-content-center ">
        <div className="">
          <Row>

            <Col lg={4} md={6} sm={12}>
              <Card style={cardStyle}>
                <Card.Img variant="top" src={machanger} />
                <Card.Body>
                  <Card.Title className="projects_titles">
                    Mac Address Changer
                  </Card.Title>
                  <Card.Text>
                    Specifically designed for changing the MAC address of
                    network interfaces on Linux systems , improving anonymity,
                    bypassing MAC address filtering on a network, or
                    troubleshooting network issues .
                  </Card.Text>
                  <a
                    href="https://github.com/MedAmyyne/Mac_Changer"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button variant="dark">Github</Button>
                  </a>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={4} md={6} sm={12}>
              <Card style={cardStyle}>
                <Card.Img variant="top" src={QuestorNet} />
                <Card.Body>
                  <Card.Title className="projects_titles">
                    Network Discovery{" "}
                  </Card.Title>
                  <Card.Text>
                    Designed for discovering devices within an IP range on a
                    network. Sending ARP requests and collects responses,
                    providing information about active devices including their
                    IP and MAC addresses.
                  </Card.Text>
                  <a
                    href="https://github.com/MedAmyyne/Network_Scanner"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button variant="dark">Github</Button>
                  </a>{" "}
                </Card.Body>
              </Card>
            </Col>

            <Col lg={4} md={6} sm={12}>
              <Card style={cardStyle}>
                <Card.Img variant="top" src={EpiScan} />
                <Card.Body>
                  <Card.Title className="projects_titles">
                    Port Scanner
                  </Card.Title>
                  <Card.Text>
                    Versatile port scanner written in Python, offering a range
                    of features to identify open ports, detect operating
                    systems, and determine service versions on target IP
                    addresses.
                  </Card.Text>
                  <a
                    href="https://github.com/MedAmyyne/Port-Scanner"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button variant="dark">Github</Button>
                  </a>
                </Card.Body>
              </Card>
            </Col>

          </Row>
        </div>
      </section>

      <section id="home" className="home d-flex justify-content-center my-5">
        <div className="">
          <Row className="mx-">

            <Col lg={4} md={6} sm={12}>
              <Card style={cardStyle}>
                <Card.Img variant="top" src={Alphascan} />
                <Card.Body>
                  <Card.Title className="projects_titles">
                    Packet Sniffer
                  </Card.Title>
                  <Card.Text>
                    Packet Sniffer written in Python , designed for capturing
                    live network packets and monitoring network activity in real
                    time, and analyze network traffic .
                  </Card.Text>
                  <a
                    href="https://github.com/MedAmyyne/Simple_Packet_Sniffer"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button variant="dark">Github</Button>
                  </a>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={4} md={6} sm={12}>
              <Card style={cardStyle}>
                <Card.Img variant="top" src={swindle} />
                <Card.Body>
                  <Card.Title className="projects_titles">
                    Arp Spoofer
                  </Card.Title>
                  <Card.Text>
                    Sw1ndle is an arp spoofer written in python , redirects
                    packets from a target host (or all hosts) on the LAN
                    intended for another host on the LAN by forging ARP replies.
                  </Card.Text>
                  <a
                    href="https://github.com/MedAmyyne/ArpSpoofer"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button variant="dark">Github</Button>
                  </a>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={4} md={6} sm={12}>
              <Card style={cardStyle}>
                <Card.Img variant="top" src={spyfire} />
                <Card.Body>
                  <Card.Title className="projects_titles">MITM</Card.Title>
                  <Card.Text>
                    Spyfire intercept and analyze network traffic, allowing to
                    operate as a "Man-in-the-Middle" (MitM) for in-depth
                    examination of data flows within a network.
                  </Card.Text>
                  <a
                    href="https://github.com/MedAmyyne/MITM"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button variant="dark">Github</Button>
                  </a>
                </Card.Body>
              </Card>
            </Col>
            
          </Row>
        </div>
      </section>
    </>
  );
};
