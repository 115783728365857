import React from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Typewriter from "typewriter-effect";
import { introdata, meta } from "../../content_option";
import { Link } from "react-router-dom";

export const About = () => {
  return (
    <HelmetProvider>
      <section id="home" className="home">
        <Helmet>
          <meta charSet="utf-8" />
          <title> {meta.title}</title>
          <meta name="description" content={meta.description} />
        </Helmet>
        <div className="intro_sec d-block d-lg-flex align-items-center ">
          <div
            className="h_bg-image order-1 order-lg-2 h-100 "
            style={{ backgroundImage: `url(${introdata.your_img_url})` }}
          ></div>
          <div className="text order-2 order-lg-1 h-100 d-lg-flex justify-content-center">
            <div className="align-self-center ">
              <div className="intro mx-auto">
                <h4 className="fluidz-48 mb-1x">~ Whoami</h4>
                <h6 className="mb-1x">
                  My name is MOHAMED AMINE MZOUGHI , Im 22 years old and i live
                  in Sahline - Monastir - Tunisia
                </h6>
                <h4 className="fluidz-48 mb-1x">~ Education</h4>
                <h6 className="mb-1x">
                  I currently pursuing the 4th degree in Cyber Security
                  Engineering at EPI Digital School - Sousse - Tunisia
                </h6>
                <h4 className="fluidz-48 mb-1x">~ Motivation</h4>
                <h6 className="mb-1x">
                  As a cybersecurity student, I am passionate about the dynamic
                  realms of penetration testing , digital forensics and Malware
                  analysis. My focus lies in proactively identifying and
                  exploiting vulnerabilities through ethical hacking methods in
                  penetration testing. Simultaneously, I am intrigued by the
                  meticulous investigation of cyber incidents and the recovery
                  of digital evidence in digital forensics. I am committed to
                  staying abreast of the latest tools and techniques in both
                  fields, participating in Capture The Flag competitions to
                  sharpen my skills, and seeking opportunities to contribute to
                  the cybersecurity community through continuous learning and
                  ethical practice. My goal is to develop a robust skill set
                  that combines technical expertise with ethical considerations
                  in securing digital landscapes.
                </h6>
                <h4 className="fluidz-48 mb-2x">~ Conclusion</h4>
                <h6 className="mb-2x">
                  Beyond the lines of code and security protocols, I believe in
                  continuous growth. I am dedicated to expanding my skill set,
                  embracing emerging technologies, and contributing to the
                  ever-evolving tapestry of the tech world.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};
