import React from "react";
import { Route, Routes} from "react-router-dom";
import withRouter from "../hooks/withRouter"
import { Home } from "../pages/home";
// import { Register } from "../pages/register";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Error } from "../pages/error";
import { About } from "../pages/about";
import { Contact } from "../pages/contact";
import { Projects } from "../pages/projects";
import { WriteUps } from "../pages/write_ups";

const AnimatedRoutes = withRouter(({ location }) => (
  <TransitionGroup>
    <CSSTransition
      key={location.key}
      timeout={{
        enter: 400,
        exit: 400,
      }}
      classNames="page"
      unmountOnExit
    >
      <Routes location={location}>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/About" element={<About />} />
      <Route exact path="/Contact" element={<Contact />} />
      <Route exact path="/Projects" element={<Projects />} />
      <Route exact path="/Write-ups" element={<WriteUps />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </CSSTransition>
  </TransitionGroup>
));

function AppRoutes() {
  return (
    <div className="s_c">
      <AnimatedRoutes />
    </div>
  );
}

export default AppRoutes;

