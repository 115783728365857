const logotext = "Mzoughi Med Amine";
const meta = {
    title: "Mzoughi Med Amine",
    description: "Mzoughi Med Amine",
};


const   introdata = {
    title: "uuЄ1I{0rn3 3457_1",
    title2: "!N M7 uu0514" ,
    animated: {
        first: "Cyber Security Guy",
        second: "Coding Enthusiaste ",
        third: "Cyber Security Warrior" ,
        fourth : "AI Enthusiaste" ,
        fifth : "Books Lover " ,
        sixth : "Competitive Programmer " ,
        seventh : "Aspiring Malware Analyst" ,
    },
    writeups : {
        first : "Look out for WriteUps soon !!" ,
    } ,
    description: "Hello! I'm Mzoughi Mohamed Amine, a passionate Cyber Security, CTF player and aspiring Malware Analyst ,i currently pursuing my degree in Cyber Security Engineering at EPI Digital School .I am constantly pushing myself to learn more and deepen my understanding of the challenges and opportunities that lie ahead in this exciting field",
    your_img_url: "https://images.unsplash.com/photo-1514790193030-c89d266d5a9d",
};


// const contactConfig = {
//     YOUR_EMAIL: "mohamed.amine.mzoughi@EPISOUSSE.com.tn",
//     YOUR_FONE: "+0000",
// };

export {
    meta,
    introdata,
    // contactConfig,
    logotext,
};

